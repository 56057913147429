


























































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import SubmitSuccess from '@/components/forms/SubmitSuccess.vue'
import { LoginCredentials, UserServerObject } from '@/store/user/types'
import { SimpleServerResponse, User } from '@/store/types'
import {
  ACCEPT_DATA_PROTECTION_DECLARATION, ACCEPT_TERMS_OF_SERVICE,
  GET_CURRENT_DECLARATION_CONTENT, GET_CURRENT_TERMS_OF_SERVICE_CONTENT
} from '@/store/user/actions.type'
import DeclarationModal from '@/components/common/DeclarationModal.vue'
import { Form } from '@/store/commonFormInput/types'
import InputTypes from '@/common/formTypes'
import BaseForm from '@/components/forms/BaseForm.vue'
import { parseUserServerObjectToUser } from '@/common/globals'

@Component({
  components: { BaseForm, SubmitSuccess, DeclarationModal }
})
export default class Login extends Vue {
  @Action('updateLoggedInUser')
  public UPDATE_LOGGED_IN_USER: (user: User) => Promise<string>

  @Action('action/user/login')
  public LOGIN: (params: LoginCredentials) => Promise<UserServerObject>

  @Action(GET_CURRENT_DECLARATION_CONTENT)
  public getCurrentDeclarationContent: (locale: string) => Promise<SimpleServerResponse>

  @Action(ACCEPT_DATA_PROTECTION_DECLARATION)
  public acceptDataProtectionDeclaration: () => Promise<SimpleServerResponse>

  @Action(GET_CURRENT_TERMS_OF_SERVICE_CONTENT)
  public getCurrentTermsOfServiceContent: (locale: string) => Promise<SimpleServerResponse>

  @Action(ACCEPT_TERMS_OF_SERVICE)
  public acceptTermsOfService: () => Promise<SimpleServerResponse>

  termsOfService = ''
  declaration = ''
  usernameState: boolean | null = null
  feedbackHeadline = ''
  feedbackInfo = ''
  showResendMail = false

  loginCredentials: LoginCredentials = { username: '', password: '' }

  get fields (): Form[] {
    return [
      {
        id: 'email',
        key: 'username',
        type: InputTypes.EMAIL,
        label: this.$i18n.t('register.email').toString(),
        md: 12,
        required: true,
        autofocus: true
      },
      {
        id: 'password',
        key: 'password',
        type: InputTypes.PASSWORD,
        label: this.$i18n.t('login.password').toString(),
        md: 12,
        trim: true,
        required: true,
        checkPattern: false
      }
    ]
  }

  get correctDeclaration (): string {
    this.getCurrentDeclarationContent(this.$root.$i18n.locale).then(data => {
      this.declaration = data.message
    }, error => {
      this.$root.$emit('alert', this.$i18n.t('warning.error!'), this.$i18n.t('login.' + error.message), true)
    })
    return this.declaration
  }

  get correctTermsOfService (): string {
    this.getCurrentTermsOfServiceContent(this.$root.$i18n.locale).then(data => {
      this.termsOfService = data.message
    }, error => {
      this.$root.$emit('alert', this.$i18n.t('warning.error!'), this.$i18n.t('login.' + error.message), true)
    })
    return this.termsOfService
  }

  declarationSubmit (event: Event): void {
    this.acceptDataProtectionDeclaration().then(() => this.login(event))
    this.$root.$emit('bv::hide::modal', 'declaration-modal-DPD')
  }

  termsOfServiceSubmit (event: Event): void {
    this.acceptTermsOfService().then(() => this.login(event))
    this.$root.$emit('bv::hide::modal', 'declaration-modal-TOS')
  }

  developement = false

  created (): void {
    this.developement = process.env.NODE_ENV === 'development'
  }

  login (event: Event): void {
    event.preventDefault()
    this.loginProcess(this.loginCredentials)
  }

  loginProcess (params: LoginCredentials): void {
    this.LOGIN(params).then(userServerObject => {
      if (userServerObject.requestStatus === 'NO_DATA_PROTECTION_LOGIN') {
        this.$root.$emit('bv::show::modal', 'declaration-modal-DPD')
      } else if (userServerObject.requestStatus === 'NO_TERMS_OF_SERVICE_LOGIN') {
        this.$root.$emit('bv::show::modal', 'declaration-modal-TOS')
      } else {
        this.UPDATE_LOGGED_IN_USER(parseUserServerObjectToUser(userServerObject)).then(firstPage => {
          this.$router.push({ name: firstPage })
        })
      }
    }).catch(error => {
      this.showResendMail = error.message === 'REGISTERED'
      this.feedbackHeadline = this.$i18n.t('warning.error!').toString()
      this.feedbackInfo = this.$i18n.t('login.' + error.message).toString()
      this.$root.$emit('alert', this.feedbackHeadline, this.feedbackInfo, true)
      this.loginCredentials = { username: '', password: '' }
      this.usernameState = null
    })
  }

  gotoSite (site: string, register: boolean): void {
    this.$emit('close')
    let params = {}

    if (register) {
      params = { role: '/' }
    }
    this.$router.push({ name: site, params: params })
  }
}
